@mixin lg {
	@media (max-width: 1240px) {
		@content;
	}
}

@mixin md {
	@media (max-width: 992px) {
		@content;
	}
}

@mixin sm {
	@media (max-width: 768px) {
		@content;
	}
}

@mixin xs {
	@media (max-width: 576px) {
		@content;
	}
}

@mixin xxs {
	@media (max-width: 360px) {
		@content;
	}
}

@mixin no-select {
	user-select: none;
	pointer-events: none;
}

@mixin visually-hidden {
	position: absolute;
	clip: rect(0 0 0 0);
	width: 1px;
	height: 1px;
	margin: -1px;
	overflow: hidden;
}

@mixin line-clamp($lines) {
	display: -webkit-box;
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
